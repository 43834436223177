import React from "react";
import "../styles/landing.css";
import { StaticImage } from "gatsby-plugin-image";

function landing() {
  return (
    <div className="landing-container">
      <div className="landing-content">
        <div className="landing-left">
          <StaticImage
            src="../images/landing.jpg"
            alt="Joeli Toscano sitting on a stool. Welcome to Joeli Toscano's website"
            className="landing-image"
            placeholder="blurred"
            loading="eager"
          />
        </div>
        <div className="landing-right">
          <div className="landing-line-top"></div>
          <div className="landing-line-right"></div>
          <h3>
            predator of experience, teller of tales, communicator to the masses,
            journalist.
          </h3>
        </div>
      </div>
    </div>
  );
}

export default landing;
