import React, { useState, useEffect } from "react";
import "../styles/photos.css";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { gallery } from "../constants/photos-list";

const Photos = () => {
  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    if (!isBrowser) return false;

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="photos-container">
      <Box>
        <ImageList variant="masonry" cols={width < 960 ? 2 : 3} gap={8}>
          {gallery.map((photo) => (
            <ImageListItem>{photo}</ImageListItem>
          ))}
        </ImageList>
      </Box>
    </div>
  );
};

export default Photos;
