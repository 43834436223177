import React from "react";
import "../styles/photos.css";
import { StaticImage } from "gatsby-plugin-image";

export const gallery = [
  <StaticImage
    className="photo-img"
    src="../images/gallery/door.jpg"
    srcSet="../images/gallery/door.jpg"
    alt="Door"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/bear.jpg"
    srcSet="../images/gallery/bear.jpg"
    alt="Bear in the woods"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/bike.jpg"
    srcSet="../images/gallery/bike.jpg"
    alt="Bike in the streets of Chicago"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/buffalo.jpg"
    srcSet="../images/gallery/buffalo.jpg"
    alt="Buffalo in a field"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/canyon.jpg"
    srcSet="../images/gallery/canyon.jpg"
    alt="Horseshoe Canyon"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/car-joeli.jpg"
    srcSet="../images/gallery/car-joeli.jpg"
    alt="Me in a car"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/chicago-lake.jpg"
    srcSet="../images/gallery/chicago-lake.jpg"
    alt="A lake in Chicago"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/flowers.jpg"
    srcSet="../images/gallery/flowers.jpg"
    alt="Flowers on a mountain"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/hike.jpg"
    srcSet="../images/gallery/hike.jpg"
    alt="Me hiking in Colorado"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/chess.jpg"
    srcSet="../images/gallery/chess.jpg"
    alt="Chess board in Westbottoms"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/liberty.jpg"
    srcSet="../images/gallery/liberty.jpg"
    alt="Statue of Liberty"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/colorado-lake2.jpg"
    srcSet="../images/gallery/colorado-lake2.jpg"
    alt="Another lake in Colorado"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/mtns.jpg"
    srcSet="../images/gallery/mtns.jpg"
    alt="Colorado mountains"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/lights.jpg"
    srcSet="../images/gallery/lights.jpg"
    alt="Power plant in Alton IL"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/newspaper.jpg"
    srcSet="../images/gallery/newspaper.jpg"
    alt="Retro news stand in Chicago"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/wolf.jpg"
    srcSet="../images/gallery/wolf.jpg"
    alt="A wolf sleeping"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/wall.jpg"
    srcSet="../images/gallery/wall.jpg"
    alt="Me by a brick wall"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/subway.jpg"
    srcSet="../images/gallery/subway.jpg"
    alt="Me looking grungy on the subway in NYC"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/timesq.jpg"
    srcSet="../images/gallery/timesq.jpg"
    alt="Times Square in NYC"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/waterfall.jpg"
    srcSet="../images/gallery/waterfall.jpg"
    alt="Standing under a waterfall in Costa Rica"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/bridge.jpg"
    srcSet="../images/gallery/bridge.jpg"
    alt="Under a bridge in California"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/library.jpg"
    srcSet="../images/gallery/library.jpg"
    alt="Me nerding out"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/jack.jpg"
    srcSet="../images/gallery/jack.jpg"
    alt="On top of a mountain in Colorado"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/motor.jpg"
    srcSet="../images/gallery/motor.jpg"
    alt="Guy on a motorbike in California"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/outfit.jpg"
    srcSet="../images/gallery/outfit.jpg"
    alt="Dressed up at the Savoy at 21C"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/venice.jpg"
    srcSet="../images/gallery/venice.jpg"
    alt="The 'V' sculpture at Venice Beach"
    loading="lazy"
    placeholder="blurred"
  />,
  <StaticImage
    className="photo-img"
    src="../images/gallery/bus.jpg"
    srcSet="../images/gallery/bus.jpg"
    alt="Color full bus in San Fran"
    loading="lazy"
    placeholder="blurred"
  />,
];
