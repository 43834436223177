import React from "react";
import "../styles/home.css";
import Layout from "../components/layout";
import Landing from "../components/landing";
import Photos from "../components/photos";

const home = () => {
  return (
    <Layout>
      <div className="home-container">
        <Landing />
        <Photos />
      </div>
    </Layout>
  );
};

export default home;
